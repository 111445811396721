var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "22",
        height: "22",
        viewBox: "0 0 22 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M9.71094 13.5078C9.71094 12.7786 9.79948 12.1979 9.97656 11.7656C10.1536 11.3333 10.4766 10.9089 10.9453 10.4922C11.4193 10.0703 11.7344 9.72917 11.8906 9.46875C12.0469 9.20312 12.125 8.92448 12.125 8.63281C12.125 7.7526 11.7188 7.3125 10.9062 7.3125C10.5208 7.3125 10.2109 7.43229 9.97656 7.67188C9.7474 7.90625 9.6276 8.23177 9.61719 8.64844H7.35156C7.36198 7.65365 7.68229 6.875 8.3125 6.3125C8.94792 5.75 9.8125 5.46875 10.9062 5.46875C12.0104 5.46875 12.8672 5.73698 13.4766 6.27344C14.0859 6.80469 14.3906 7.55729 14.3906 8.53125C14.3906 8.97396 14.2917 9.39323 14.0938 9.78906C13.8958 10.1797 13.5495 10.6146 13.0547 11.0938L12.4219 11.6953C12.026 12.0755 11.7995 12.5208 11.7422 13.0312L11.7109 13.5078H9.71094ZM9.48438 15.9062C9.48438 15.5573 9.60156 15.2708 9.83594 15.0469C10.0755 14.8177 10.3802 14.7031 10.75 14.7031C11.1198 14.7031 11.4219 14.8177 11.6562 15.0469C11.8958 15.2708 12.0156 15.5573 12.0156 15.9062C12.0156 16.25 11.8984 16.5339 11.6641 16.7578C11.4349 16.9818 11.1302 17.0938 10.75 17.0938C10.3698 17.0938 10.0625 16.9818 9.82812 16.7578C9.59896 16.5339 9.48438 16.25 9.48438 15.9062Z",
          fill: "white",
        },
      }),
      _c("circle", {
        attrs: {
          cx: "11",
          cy: "11",
          r: "10",
          stroke: "white",
          "stroke-width": "2",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }